import {TwitterTimelineEmbed} from 'react-twitter-embed';
import "../css_files/HomePage.css"
import React from "react";
import 'aos/dist/aos.css';
import Team from "./Team";
import axios from "axios";
export default function MainHomePage() {
    const[data,setData] = React.useState([]);
    React.useEffect(()=>{
        axios
            .get("/media/data/contents.json")
            .then((res)=>
            {

                setData(res.data)
            })
            .catch(err=>console.log(err))
    },[]);
    return (
        <div id={"homeDiv"} className={"container-fluid"}>
            <div className={"row"} id={"aboutUs"}>
                <div className={"col"} id={"aboutUsDiv"} >
                    <div className={"row"}>
                        <div className={"col"} >

                            <div>
                                <p className={"text"} id={"informationText"}>
                                    {data.informationText}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                    <div id={"twitterFeedDiv"} className={"col"}>

                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="NeuraSearch"
                            options={{height: 0}}
                        />


                </div>
            </div>
            <Team/>
        </div>
    )
}
