
import "../css_files/Team.css"
import React from "react";
import UserInfo from "./UserInfo";

import Filters from "./Filters";
import MobileFilters from "./MobileFilters";
import axios from "axios";
function Team (){


        //data for the page

        // eslint-disable-next-line react-hooks/rules-of-hooks
    const[users,setUsers] = React.useState([]);
    const[originalUsers,setOriginalUsers] = React.useState([]);
    const[lists,setLists] = React.useState([]);

    React.useEffect(()=>{
        axios
            .get("/media/data/users.json")
            .then((res)=>
        {
            console.log(res.data.users)
            setUsers(res.data.users);
            setOriginalUsers(res.data.users);
            createLists(res.data.users);
        }
            )
            .catch(err=>console.log(err))
    },[]);

    // clearing filters
    async function clearFilters(){
        let copyOfUsers = [...originalUsers];
        await setUsers(copyOfUsers);
        await createLists(copyOfUsers);
        document.getElementById("teamContainer").style.height="fit-content"
    }

    //sorting and filtering users
    async function sortUsers(sortByOption,projects,position,topics,filterPastMembers){
        //Just for year now and will do different filters later
        console.log(position)
        console.log(topics)
        console.log(sortByOption)
        let copyOfUsers = [...originalUsers];
        let overall_result = [];
        if(filterPastMembers===true){
            copyOfUsers =  copyOfUsers.filter(user=>user.tags.year==1000)
        }
        if(position.length!==0){
            let result = [];
            for (var i=0; i<position.length; i++){
                const position_chosen = position[i];
                result = result.concat( copyOfUsers.filter(user=>user.tags.position.name==position_chosen));
            }
            overall_result = overall_result.concat(result);
        }
        if(projects.length!==0){
            let result = [];
            for (var i=0; i<projects.length; i++){
                const project = projects[i];
               result = result.concat( copyOfUsers.filter(user=>user.tags.projects.includes(project)));
            }
            overall_result = overall_result.concat(result);
        }
        if (topics.length!==0){
            let result = [];
            for (var j=0; j<topics.length; j++){
                const topic = topics[j];
                result = result.concat( copyOfUsers.filter(user=>user.tags.topics.includes(topic)));
            }
            overall_result = overall_result.concat(result);
        }
        if (sortByOption==="year") {
            if(overall_result.length==0){
                overall_result = [...copyOfUsers];
            }
            overall_result = overall_result.sort((a, b) => {
                const valueA = a.tags.year;
                const valueB = b.tags.year;
                if (valueA > valueB) {
                    return -1;
                }
                if (valueA < valueB) {
                    return 1;
                }
                return 0;
            });
        }else if (sortByOption==="affiliation"){
            if(overall_result.length==0){
                overall_result = [...copyOfUsers];
            }
            overall_result = overall_result.sort((a, b) => {
                const valueA = a.tags.affiliation;
                const valueB = b.tags.affiliation;
                if (valueA > valueB) {
                    return -1;
                }
                if (valueA < valueB) {
                    return 1;
                }
                return 0;
            });

        }else if (sortByOption==="role"){
            if(overall_result.length==0){
                overall_result = [...copyOfUsers];
            }
            overall_result = overall_result.sort((a, b) => {
                const valueA = a.tags.position.weight;
                const valueB = b.tags.position.weight;
                if (valueA > valueB) {
                    return -1;
                }
                if (valueA < valueB) {
                    return 1;
                }
                return 0;
            });

        }
        // remove duplication
        if(overall_result.length==0){
            overall_result = [...copyOfUsers];
        }
        if(copyOfUsers.length===0){
            overall_result = [...originalUsers];
        }
        overall_result = removeDuplication(overall_result);

       await setUsers(overall_result);

       await createLists(overall_result);
       if(window.screen.width>1366) {
           document.getElementById("teamContainer").style.height = "100vh"
       }
    }





    // remove duplicate objects
    function removeDuplication(results){
        const uniqueArray = results.filter(function(item, pos, self) {
            return self.indexOf(item) == pos;
        })
        return uniqueArray;
    }
    function getHeader(){
        if (window.screen.width>1366) {
            return (
                <h1 className={"display-1 headerText"} id={"teamsDiv"}>
                    Our Team
                </h1>
            );
        }
        else{
            return (
                <h1 className={"display-4 headerText"} id={"teamsDiv"}>
                    Our Team
                </h1>
            );
        }
    }
    //html

        return (
                    <div data-aos={"fade-up"} >
                        {getHeader()}
                        <div id={"mobileFiltersDiv"}>
                            <MobileFilters  sortUsers={sortUsers} clearUsers = { clearFilters}/>
                        </div>
                        <div id={"mainTeamsPage"} >



                            <Filters sortUsers={sortUsers} clearUsers = { clearFilters}/>
                            <div id='teamContainer' className='container-fluid'>

                                        {lists.map(list => {
                                            return (<div className='row userRow'>
                                                    {
                                                        list.map(user => {
                                                            return (<UserInfo user_object={user} />);
                                                        })
                                                    }
                                                    </div>
                                                );
                                        })}
                            </div>
                        </div>
                    </div>

        );


    async function createLists(users){
        console.log("The users")
        console.log(users)
        const number_of_users = users.length;
        let list_to_append = []
        let temp_lists = []
        //await setLists([]);
        for (var i =0; i<number_of_users; i++) {
            list_to_append.push(users[i]);
            if(list_to_append.length === 3 || i==number_of_users-1){
                temp_lists.push(list_to_append);
                list_to_append = []
            }

        }
        console.log("The temp lists")
        console.log(temp_lists)
        await setLists(temp_lists);
    }

}export default Team
