import React from 'react';
import { Slide } from 'react-slideshow-image';
import "../css_files/ImageSlider.css";
import 'react-slideshow-image/dist/styles.css'
import axios from "axios";
function SlideShow (){

    const[images,setImages] = React.useState([]);
    React.useEffect(()=>{
        axios
            .get("/media/data/images.json")
            .then((res)=>setImages(res.data.images.slider))
            .catch(err=>console.log(err))
    },[]);

        return (
            <div id={"imageSlider"}>
                <Slide easing="ease">

                        {
                            images.map(image => {
                                return (

                                        <a href={image.url} className={"sliderImage"}>
                                            <img src={image.src} className={"sliderImage"}/>
                                        </a>

                                );
                            })
                        }
                </Slide>
            </div>
        )

}export default SlideShow;
