import '../css_files/App.css';
import {BrowserRouter as Router, Route} from "react-router-dom";
import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomePage from "./HomePage";
import "../css_files/MobileStyleSheet.css"
AOS.init();
function App() {

  return (
        <div className="App">

            <Router>
                <Route path={"/"} component={HomePage} exact/>
            </Router>

        </div>
  );
}

export default App;
