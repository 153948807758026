
import React from "react";
import MainHomePage from "./MainHomePage";
import "../css_files/App.css"
import NavigationBar from "./NavigationBar";
import ImageSlider from "./ImageSlider";
import Footer from "./Footer";
import axios from "axios";
function HomePage (){
        return(
            <div id = {"mainDiv"}>
                <ImageSlider/>
                <NavigationBar/>
                <MainHomePage/>
                <Footer/>
            </div>
        );
}export default HomePage
