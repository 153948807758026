import React from "react";
import "../css_files/UserInfo.css"
import "../css_files/hover.css"
import Popup from 'reactjs-popup';
import {Button} from "@material-ui/core";
class UserInfo extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            "data" : props,
        };
    }

    render() {
        return(

            <div className={"col userInfo"}>
                <div className={"nonExpandableDiv row members"}>
                    {this.get_picture()}
                        <div className={"row"}>
                        <p className={"userText text"}>
                            Name : {this.get_link(this.props.user_object.name)}<br/>
                            PhD year : {this.props.user_object.year}<br/>
                            PhD title :
                            {this.props.user_object.title}<br/>
                            <Popup className={"showMorePopUp"}
                                   trigger={open => (
                                       <a  className={"showMoreButton moreLink"}>
                                           More ...
                                       </a>
                                   )}
                                   position="bottom left"
                                   closeOnDocumentClick
                            >
                                <div className={"expandableDiv"} >
                                    <p className={"text more_info"}>
                                        PhD topic : <br/>{this.props.user_object.phd_topic}<br/><br/>
                                        Current working project : <br/>
                                        {this.props.user_object.current_project}<br/><br/>
                                        Bio : <br/>
                                        {this.props.user_object.short_bio}<br/>

                                    </p>
                                </div>
                            </Popup>
                        </p>

                        </div>
                </div>
            </div>

        )
    }
    get_link(name){

            if(this.props.user_object.link_to_website!=="#" && this.props.user_object.link_to_website!==""){
                return(
                    <a href={this.props.user_object.link_to_website} className={"nameA"}>{name}</a>
                )
            }else{
                return (

                        <p className={"text nametext"}>
                            {name}
                        </p>

                )
            }


    }

    get_picture(){
        if(this.props.user_object.picture!==""){

            return(
                <div className={"imageContainer row"}>
                    <img className={"userPhoto"} src={this.props.user_object.picture} alt={""}/>
                </div>
            )
        }else{
            return(
                <svg xmlns="http://www.w3.org/2000/svg"  fill="#3f51b5"
                     className="bi bi-person-circle userPhoto row" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg>
            )
        }
    }


}

export default UserInfo