import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Button,
    Checkbox,
    FormControl,
    InputBase,
    ListItemText,
    makeStyles,
    MenuItem,
    withStyles
} from "@material-ui/core";
import Popup from "reactjs-popup";
import axios from "axios";

function MobileFilters(props){

    //styles
    const BootstrapInput = withStyles((theme) => ({
        root: {
            'label + &': {
                marginTop: theme.spacing(0),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }))(InputBase);

    //styles
    const useStyles = makeStyles((theme) => ({
        margin: {
            margin: theme.spacing(1),
        },
    }));
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    // data for the html
    // data for the html
    const [allProjects,setAllProjects] = React.useState([]);
    const [topics,setAllTopics]=React.useState([]);
    const [positions,setPositions] = React.useState([]);
    const [sorting,setSorting] = React.useState([]);

    React.useEffect(()=>{
        axios
            .get("/media/data/filters.json")
            .then((res)=>
                {
                    setAllProjects(res.data.filters.projects);
                    setAllTopics(res.data.filters.topics);
                    setPositions(res.data.filters.positions);
                    setSorting(res.data.filters.sort);
                }
            )
            .catch(err=>console.log(err))
    },[]);

    // change hooks
    const classes = useStyles();
    const [position, setPosition] = React.useState([]);
    const [userTopics, setTopics] = React.useState([]);
    const [projects,setProjects] = React.useState([])
    const [sort,setSort] = React.useState('');
    const [filteringPastMembers,setFilteringPastMembers] = React.useState(false);

    //change functions
    const handlePastMembersChange = (event)=>{
        props.sortUsers(sort,projects,position,userTopics,event.target.checked);
        setFilteringPastMembers(event.target.checked);
    };
    const handleSortChange = (event) => {
        console.log(event.target.value);
        props.sortUsers(event.target.value,projects,position,userTopics,filteringPastMembers);
        setSort(event.target.value);
    };
    const handlePositionChange = (event) => {

        let copyOfPosition = [...position];
        if (event.target.checked === true) {
            copyOfPosition.push(event.target.value);
            props.sortUsers(sort, projects, copyOfPosition, userTopics,filteringPastMembers);
            setPosition(copyOfPosition);
        } else {
            for (var i = 0; i < copyOfPosition.length; i++) {
                if (copyOfPosition [i] == event.target.value) {
                    delete copyOfPosition [i];
                    break;
                }
            }
            props.sortUsers(sort, projects, copyOfPosition, userTopics,filteringPastMembers);
            setPosition(copyOfPosition);

        }
    }
    function handleTopicChange(event){
        let copyOfUserTopics = [...userTopics];
        if (event.target.checked===true){
            copyOfUserTopics.push(event.target.value);
            props.sortUsers(sort,projects,position,copyOfUserTopics,filteringPastMembers);
            setTopics(copyOfUserTopics);
        }else{
            for (var i=0; i<copyOfUserTopics.length; i++){
                if(copyOfUserTopics[i]== event.target.value){
                    delete copyOfUserTopics[i];
                    break;
                }
            }
            props.sortUsers(sort,projects,position,copyOfUserTopics,filteringPastMembers);
            setTopics(copyOfUserTopics);
        }
    }
    function  handleProjectsChange(event) {
        console.log(event.target.checked)
        let copyOfUserProjects = [...projects];
        if (event.target.checked===true){
            copyOfUserProjects.push(event.target.value);
            props.sortUsers(sort,copyOfUserProjects,position,userTopics,filteringPastMembers);
            setProjects(copyOfUserProjects);
        }else{
            for (var i=0; i<copyOfUserProjects.length; i++){
                if(copyOfUserProjects[i]== event.target.value){
                    delete copyOfUserProjects[i];
                    break;
                }
            }
            props.sortUsers(sort,copyOfUserProjects,position,userTopics,filteringPastMembers);
            setProjects(copyOfUserProjects);
        }

    }

    const clearFilters = (cleanUsers)=>{
        setPosition([]);
        setProjects([]);
        setTopics([]);
        setSort("");
        setFilteringPastMembers(false);

    }
    function makeRoleFilterVisible (filtersId,buttonId){
        console.log("clicking")
        var visibility = document.getElementById(filtersId).style.display;
        if (visibility === "none"){
            document.getElementById(filtersId).style.display="block";
            document.getElementById(buttonId).style.transform = 'rotate(90deg)';
        }else{
            document.getElementById(filtersId).style.display="none";
            document.getElementById(buttonId).style.transform = 'rotate(360deg)';
        }
    }
    function openFilters(){
        console.log("Clicking")
        var display = document.getElementById("expandableFiltersDiv").style.display;
        if(display==="none"){
            document.getElementById("expandableFiltersDiv").style.display = "block"
        }else{
            document.getElementById("expandableFiltersDiv").style.display = "none"
        }
    }
    return(
        <div id={"mobileFilters"}>

           <div id={"filtersButtonDiv"} onClick={openFilters}>
               <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor"
                    className="bi bi-funnel" viewBox="0 0 16 16" id={"filtersButton"}>
                   <path id={"filtersSvg"}
                       d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
               </svg>
           </div>
            <div className={"expandableDiv container-fluid"} id={"expandableFiltersDiv"}>
                <div className={"row mobileFilterRow"}>
                    <FormControl classes={classes.margin} className={"container-fluid"} id={"filterForm"} >
                        <div className={"row mobileFilterRow"}>
                            <Typography className={"col filters_text"}>
                                Role
                            </Typography>
                            <div className={"col"} onClick={()=>{makeRoleFilterVisible("roleFiltersMobile","roleButtonMobile")} } id={"roleColumn"} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f51b5"
                                     className="bi bi-arrow-right-square-fill" viewBox="0 0 16 16" id={"roleButtonMobile"}>
                                    <path
                                        d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"row"} >
                            <FormControl className={classes.margin} id={"roleFiltersMobile"} >
                                {positions.map((name) =>(
                                    <MenuItem key={name} value={name} className={"mobileFiltersItem"}>
                                        <Checkbox color={"primary"} checked={position.indexOf(name) > -1} onChange={handlePositionChange} value={name} className={"filters_checkbox"}/>
                                        <ListItemText primary={name} className={"filters_checkbox"}/>
                                    </MenuItem>
                                ))}
                            </FormControl>
                        </div>
                        <hr className={"line"}/>
                        <div className={"row mobileFilterRow"}>
                            <Typography className={"col filters_text"}>
                                Topics
                            </Typography>
                            <div className={"col"} onClick={()=>{makeRoleFilterVisible("topicsFiltersMobile","topicsButtonMobile")} } id={"topicsColumn"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f51b5"
                                     className="bi bi-arrow-right-square-fill" viewBox="0 0 16 16" id={"topicsButtonMobile"}>
                                    <path
                                        d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"row checkboxes"} >
                            <FormControl  id={"topicsFiltersMobile"}>
                                {topics.map((name) =>(
                                    <MenuItem key={name} value={name} className={"mobileFiltersItem"}>
                                        <Checkbox color={"primary"} checked={userTopics.indexOf(name) > -1} onChange={handleTopicChange} value={name} className={"filters_checkbox"}/>
                                        <ListItemText primary={name} className={"filters_checkbox"}/>
                                    </MenuItem>
                                ))}
                            </FormControl>
                        </div>
                        <hr className={"line"}/>
                        <div className={"row mobileFilterRow"}>
                            <Typography className={"col filters_text"}>
                                Projects
                            </Typography>
                            <div className={"col"} onClick={()=>{makeRoleFilterVisible("projectsFiltersMobile","projectsButtonMobile")} } id={"projectsColumn"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f51b5"
                                     className="bi bi-arrow-right-square-fill" viewBox="0 0 16 16" id={"projectsButtonMobile"}>
                                    <path
                                        d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"row"} id={"projectsFiltersMobile"}>
                            <FormControl >
                                {allProjects.map((name) =>(
                                    <MenuItem key={name} value={name} className={"mobileFiltersItem"}>
                                        <Checkbox color={"primary"} checked={projects.indexOf(name) > -1} onChange={handleProjectsChange} value={name} className={"filters_checkbox"}/>
                                        <ListItemText primary={name} className={"filters_checkbox"}/>
                                    </MenuItem>
                                ))}
                            </FormControl>
                        </div>
                        <hr className={"line"}/>
                        <div className={"row mobileFilterRow"}>
                            <Typography className={"col filters_text"}>
                                Sort By
                            </Typography>
                            <div className={"col"} onClick={()=>{makeRoleFilterVisible("sortFiltersMobile","sortButtonMobile")} } id={"sortColumn"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f51b5"
                                     className="bi bi-arrow-right-square-fill" viewBox="0 0 16 16" id={"sortButtonMobile"}>
                                    <path
                                        d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"row"}>
                            <FormControl id={"sortFiltersMobile"} >
                                {sorting.map((name) => (

                                    <MenuItem key={name} value={name} className={"mobileFiltersItem"}>
                                        <Checkbox color={"primary"} checked={name===sort} onChange={handleSortChange} value={name} className={"filters_checkbox"}/>
                                        <ListItemText primary={name} className={"filters_checkbox"}/>
                                    </MenuItem>
                                ))}
                            </FormControl>
                        </div>

                    <hr className={"line"}/>
                    <div className={"row mobileFilterRow"}>
                        <div className={"col filters_text"}>
                            <Typography>
                                Past members
                            </Typography>
                        </div>
                        <div className={"col"} id={"pastMembersFiltersMobile"}>
                            <FormControl >
                                <MenuItem key={"past_members"} value={"past_members"}>
                                    <Checkbox color={"primary"} checked={filteringPastMembers} onChange={handlePastMembersChange} className={"filters_checkbox"}/>
                                </MenuItem>
                            </FormControl>
                        </div>
                    </div>
                    <hr className={"line"}/>
                    <div className={"row mobileFilterRow"}>
                        <div className={"col mobileFilterCol "}>
                            <Button variant="contained" color="primary" id={"clearButton"} onClick={()=>{clearFilters(); props.clearUsers()}} >
                                Clear filters
                            </Button>
                        </div>

                    </div>
                    </FormControl>
                </div>

            </div>
        </div>
    );
}export default MobileFilters;
