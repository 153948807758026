import Navbar from 'react-bootstrap/Navbar'
import Nav from "react-bootstrap/Nav"
import "../css_files/NavigationBar.css"
import "../css_files/Sizes.css"
import React from "react";
import {Link} from "react-scroll"
class NavigationBar extends React.Component{
     async animate(){
        var paragraph = document.getElementById("welcomeParagraph")
        var animations=["bounce","fade_out_class"];
        paragraph.classList.add("bounce");
         await this.sleep(7000)
         paragraph.innerHTML="<span id='NSpan'>N</span>eura<span id='SSpan'>S</span>earch Lab";

    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    setText(){
        var paragraph = document.getElementById("welcomeParagraph")

    }
    setLabelStyling(){
         if(window.screen.height === 823 ){
             var paragraph = document.getElementById("welcomeParagraph");
             paragraph.style.paddingTop="1.5vh";
             paragraph.style.right="6vw";
         }
         else if(window.screen.height < 1024){
             var paragraph = document.getElementById("welcomeParagraph");
             paragraph.style.paddingTop="1vh";
             paragraph.style.right="9vw";
         }
    }
    componentDidMount() {
         this.animate();
         this.setLabelStyling();
    }

    getHeading(){
        console.log("Trying to find label")
        if( window.screen.width>1366) {
            return (
                <div className={"container-fluid"} id={"welcomeContainer"}>
                    <div className={"row"}>
                        <img src={"/images/NeuraSearch-2.png"} width="200" height="200" id={"logo"}/>
                    </div>

                    <div className={"row"}>
                        <p id={"welcomeParagraph"} className={"text"} >
                            <span className={"fade_out_class"}>Welcome to <span id={"NSpan"}>N</span>eura<span id={"SSpan"}>S</span>earch Lab</span>
                        </p>
                    </div>
                </div>
            );
        }else{
            return(
                <div id={"welcomeContainer"}>
                    <img src={"/images/NeuraSearch-2.png"} width="200" height="200" id={"logo"} />
                    <br/>
                    <p id={"welcomeParagraph"} className={"text"}>
                       <span id={"NSpan"}>N</span>eura<span id={"SSpan"}>S</span>earch Lab
                    </p>
                </div>
            );
        }
    }
    render() {
        return (
            <div id={"outerNavigation"}>
                {this.getHeading()}
                <div id={"navigationBar"} onresize={this.getHeading()}>

                    <Navbar collapseOnSelect expand="lg" className={"bg-transparent "}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">

                            <Nav className={"mr-auto nav-fill w-100 "}>
                                <Nav.Item className={"hvr-grow-shadow"}>
                                    <Nav.Link href="/">Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={"hvr-grow-shadow"}>
                                    <Link id={"teamsNavButton"} to={"teamsDiv"} smooth={true} offset={0}
                                          duration={1000}>
                                        Team
                                    </Link>
                                </Nav.Item>

                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        );
    }
}
export default NavigationBar
